import createResource from "@enymo/react-resource-hook";
import { isNotNull } from "@enymo/ts-nullsafe";
import { DeepPartial } from "ts-essentials";
import { WeekTimes } from "./Components/WeekTimeInput";
import { HashtagDifficulty, SubscriptionTier, aiStyles, languages, months, toISOStringWithTimezone, weekdays } from "./common";
import { SocialAccountType } from "./types";

type BillingPeriod = "monthly" | "yearly"

export interface SubscriptionData {
    tier: SubscriptionTier,
    billing_period: BillingPeriod,
    valid_from: Date,
    additional_social_accounts: number,
    next_billing_date?: Date,
}

export interface ApiSubscripitonData extends Omit<SubscriptionData, "valid_from" | "tier" | "next_billing_date"> {
    valid_from: string,
    subscription_tier: SubscriptionTier,
}

export interface User {
    id: number | "me",
    first_name: string,
    last_name: string,
    email: string,
    email_verified_at: Date | null,
    language: typeof languages[number],
    timezone: string,
    admin: boolean,
    restricted: boolean,
    openai: boolean,
    jwt: string,
    has_password: boolean,
    has_google: boolean,
    has_facebook: boolean,
    has_linkedin: boolean,
    has_avatar: boolean,
    avatar_version: number,
    assigned_subscription_tier: SubscriptionTier | null,
    assigned_subscription_expires_at: Date | null,
    billed_subscription: SubscriptionData,
    next_billed_subscription?: SubscriptionData,
    subscription_tier: SubscriptionTier, // This is the tier the user is currently using
    invoice_name?: string,
    invoice_address_1?: string,
    invoice_address_2?: string,
    invoice_postal_code?: string,
    invoice_city?: string,
    invoice_country?: string,
    invoice_vat_id?: string,
    invoice_vat_type?: string,
    invoice_state?: string,
    can_activate_campaigns: number,
}

interface ApiUser extends Omit<User, "email_verified_at" | "assigned_subscription_expires_at" | "billed_subscription" | "next_billed_subscription" | "avatar_version"> {
    email_verified_at: string,
    assigned_subscription_expires_at: string | null,
    billed_subscription: ApiSubscripitonData | null,
    next_billed_subscription: ApiSubscripitonData | null,
}

export interface UserUpdate extends Omit<User, "assigned_subscription_expires_at"> {
    currentPassword: string,
    password: string,
    assigned_subscription_expires_at: string | Date | null,
    google_id: null,
    facebook_id: null,
    linkedin_id: null
}

export const userTransformer = ({
    email_verified_at,
    assigned_subscription_expires_at,
    billed_subscription,
    next_billed_subscription,
    ...user
}: Partial<ApiUser>): Partial<User> => {
    const valid_from = billed_subscription ? new Date(billed_subscription.valid_from) : new Date();
    
    // Calculate from valid_from and billing_period
    const next_billing_date = new Date(valid_from);
    const daysToAdd = billed_subscription?.billing_period === "monthly" ? 30 : 365;
    next_billing_date.setDate(next_billing_date.getDate() + daysToAdd);
    return {
        email_verified_at: isNotNull(email_verified_at) ? new Date(email_verified_at) : email_verified_at,
        assigned_subscription_expires_at: isNotNull(assigned_subscription_expires_at) ? new Date(assigned_subscription_expires_at) : assigned_subscription_expires_at,
        billed_subscription: !billed_subscription ? {
            tier: "free",
            billing_period: "monthly",
            additional_social_accounts: 0,
            valid_from: new Date(),
            next_billing_date: new Date(),
        } : {
            ...billed_subscription,
            tier: billed_subscription.subscription_tier,
            valid_from: new Date(billed_subscription.valid_from),
            next_billing_date,
        },
        next_billed_subscription: !next_billed_subscription ? undefined : {
            ...next_billed_subscription,
            tier: next_billed_subscription.subscription_tier,
            valid_from: new Date(next_billed_subscription.valid_from),
        },
        avatar_version: 0,
        ...user
    }
};

export const [useUsers, UsersProvider] = createResource<User, UserUpdate>("users", {
    transformer: userTransformer,
})

export interface AISetttings {
    id: "single"
    ai_text: string,
    clone_rephrase: string,
    objective_tone: string,
    funny_tone: string,
}

export const [useAISettings] = createResource<AISetttings>("ai-settings");

export interface SocialAccount {
    id: number,
    user_id: number,
    name: string,
    type: SocialAccountType,
    healthy: boolean,
    has_avatar: boolean
}

export const [useSocialAccounts, SocialAccountsProvider] = createResource<SocialAccount>("social-accounts", {
    defaultUpdateMethod: "immediate"
});

export type SocialChannelStatus = "ok" | "will_expire" | "expired";

export interface SocialChannel {
    id: number,
    account: {
        id: number,
        type: SocialAccountType,
        status: SocialChannelStatus
    }
    icon_type: SocialAccountType,
    type: string,
    name: string,
    has_avatar: boolean,
    character_limit: number,
    campaigns?: {
        id: number
    }[]
}

export const [useSocialChannels, SocialChannelsProvider] = createResource<SocialChannel>("social-channels", {
    defaultUpdateMethod: "immediate"
})

export interface Campaign {
    id: number,
    previous_id: number | null,
    is_owner: boolean,
    active: boolean,
    name: string,
    start_date: Date | null,
    end_date: Date | null,
    timezone: string,
    notes: string | null,
    rotate: boolean,
    frequency: "daily" | "weekly" | "monthly" | "yearly",
    interval: number,
    daily_posts: number,
    posting_times: WeekTimes<string>,
    excluded_months: typeof months[number][],
    weekdays: typeof weekdays[number][],
    days_of_month: (number | "last")[],
    dates: {
        day: number,
        month: number
    }[],
    ai_description: string | null,
    ai_style: typeof aiStyles[number],
    active_social_channels: number[],
    owner: {
        id: number,
        first_name: string,
        last_name: string
    },
    can_activate_posts: number,
    thumbnail_time: number,
}

export interface ApiCampaign extends Omit<Campaign, "start_date" | "end_date" | "previous_id" | "is_owner"> {
    start_date: string | null,
    end_date: string | null,
    pivot: {
        previous_id: number | null,
        is_owner: boolean
    }
}

export const [useCampaigns, CampaginsProvider] = createResource<Campaign>("campaigns", {
    transformer: ({
        start_date,
        end_date,
        pivot,
        posting_times,
        ...campaign
    }: ApiCampaign) => ({
        start_date: isNotNull(start_date) ? new Date(start_date) : null,
        end_date: isNotNull(end_date) ? new Date(end_date) : null,
        posting_times: Object.fromEntries(Object.entries(posting_times).map(([weekday, times]) => [weekday, times.toSorted()])),
        ...pivot,
        ...campaign
    }),
    inverseTransformer: ({
        start_date,
        end_date,
        ...campaign
    }): DeepPartial<ApiCampaign> => ({
        start_date: toISOStringWithTimezone(start_date),
        end_date: toISOStringWithTimezone(end_date),
        ...campaign
    })
});

export interface CampaignUser extends User {
    pivot: {
        is_owner: boolean
    }
}

export const [useCampaignUsers] = createResource<CampaignUser>("campaigns.users");
export interface Hashtag {
    id: number,
    name: string,
    total_posts?: number,
    average_likes?: number,
    posts_per_hour?: number,
    difficulty?: HashtagDifficulty,
}

export interface HashtagList {
    id: number,
    name: string,
    user_id: number,
    hashtags?: Hashtag[],
}

export const [useHashtagLists] = createResource<HashtagList>("hashtag-lists");
export const [useHashtagListHashtags] = createResource<Hashtag>("hashtag-lists.hashtags", {
    socketEvent: "hashtags",
});

export interface HashtagSearch {
    id: number,
    search_term: string,
    hashtags: Hashtag[],
}

export const [useHashtagSearches] = createResource<HashtagSearch>("hashtag-searches");


export interface PaymentMethod {
    id: string;
    type: "mastercard" | "visa" | "amex" | "sepa" | "paypal";
    last4?: string;
    exp_month?: number;
    exp_year?: number;
    email?: string;
    in_use: boolean;
}

export const [usePaymentMethods, PaymentMethodProvider] = createResource<PaymentMethod>("payment-methods");
export interface Post {
    id: number,
    campaign_id: number,
    previous_id: number | null,
    active: boolean,
    last_posted_at: Date | null,
    first_text: string,
    text_count: number,
    media_count: number,
    editing_state: "draft" | "draft_of" | "available",
    should_repost: boolean,
    settings: {
        facebook?: {
            post: boolean,
            story: boolean,
            reel: boolean,
            show_reel_in_feed: boolean,
        },
        tiktok?: {
            allow_comments: boolean,
            video_is_sponsored: boolean,
            add_music: boolean,
        },
        youtube?: {
            visibility: "public" | "unlisted" | "private",
        }
    },
    dirty: boolean,
    draft_of: number | null,
    draft_id: number | null,
}

export interface ApiPost extends Omit<Post, "last_posted_at"> {
    last_posted_at: string | null,
}

export interface PostUpdate extends Post {
    publish: boolean
}

export const [usePosts, PostsProvider] = createResource<Post, PostUpdate>("campaigns.posts", {
    pruneUnchanged: false,
    transformer: ({
        last_posted_at,
        ...post
    }: ApiPost) => ({
        last_posted_at: isNotNull(last_posted_at) ? new Date(last_posted_at) : null,
        ...post
    }),
});

export interface PostText {
    id: number,
    post_id: number,
    previous_id: number | null,
    text: string,
    hashtags: Hashtag[],
    text_plain: string,
    hashtags_string: string,
    title: string | null,
}

export const [usePostTexts] = createResource<PostText>("posts.texts");

export interface PostMedium {
    id?: number,
    post_id: number,
    file_id: number,
    previous_id: number | null,
    has_thumbnail: boolean,
    media_type: "image" | "video",
    url: string,
    file?: File,
    client_id?: string,
    restrictions?: string[]
}

interface ApiPostMedium extends PostMedium {
    pivot?: {
        previous_id: number | null,
        post_id: number,
        file_id: number,
        thumbnail_file_id: number | null,
        restrictions: string[]
    }
}

export const [usePostMedia] = createResource<PostMedium>("posts.media", {
    paramName: "medium",
    transformer: ({
        pivot,
        ...medium
    }: ApiPostMedium) => ({
        ...medium,
        post_id: pivot?.post_id,
        file_id: pivot?.file_id,
        previous_id: pivot?.previous_id,
        restrictions: pivot?.restrictions,
        has_thumbnail: isNotNull(pivot?.thumbnail_file_id)
    }),
});

export interface Schedule {
    id: number,
    campaign_id: number
    date: Date
}

interface ApiSchedule extends Omit<Schedule, "date"> {
    date: string
}

export const [useSchedule] = createResource<Schedule>("schedule", {
    transformer: ({
        date,
        ...schedule
    }: ApiSchedule) => ({
        date: new Date(date),
        ...schedule
    })
});


export const [useCampaignHashtags] = createResource<Hashtag>("campaigns.hashtags");